.slideshow {
    position: relative;
    overflow: hidden;
  }
  
  .slideContainer {
    display: flex;
    flex-direction: row;
    transition: transform 1s ease;
  }
  
  .slide {
    flex: 0 0 100%;
    transform: translateX(100%);
  }
  
  .active {
    animation: slideFromRight 20s ease forwards
    /* animation-delay: 5s; */
  }
  
  @keyframes slideFromRight {
    0% {
      transform: translateX(100%);
    }
    50% {
      transform: translateX(0);
    }
    75% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
  }


  
  .quote {
 
    font-weight: bold;
  }
  
  .author {
 
    font-style: italic;
    margin-top: 8px;
  }

 
  